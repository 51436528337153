import React from 'react';

import { Helmet } from 'react-helmet';

import Header from 'components/common/header';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import {
	PageContainer,
	SectionTitle,
	ContentText,
	StaticList,
	SectionSubTitle,
	Spacer,
	LinkButton,
	LinkText,
} from './styles';
import { Video } from 'lib/Video';
import routes from 'config/routes';

const meta = PageMeta.getStatic('landlord_resources');

const LandlordResources = () => {
	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header searchable />
			<TitleBar title="Landlord Resources"></TitleBar>
			<PageContainer>
				<SectionTitle>Welcome landlords!</SectionTitle>
				<ContentText>
					We’ve put together some resources and useful tips to help you better understand how our
					platform works. After all, we want your listings to do as well as possible: what helps
					you, helps us.
				</ContentText>
				<SectionTitle>How to take great photographs of your property</SectionTitle>

				<ContentText>
					Having amazing photos of your property is incredibly important when it comes to creating
					interest. At the end of the day, a picture is worth a thousand words. For the best
					results, we recommend uploading at least 5 high-quality pictures, with the first image
					being of the front of the property from outside.
				</ContentText>
				<ContentText>
					To help you channel your inner photographer and get the best lighting and angles with your
					cell phone, check out the video below.
				</ContentText>

				<Spacer large>
					<Video videoUrl="https://www.youtube.com/embed/ggBLxQiOVuU" />
				</Spacer>
				<SectionTitle>How to improve the ranking of your listing</SectionTitle>
				<ContentText indent>
					It is our goal to help you, as a landlord, fill your accommodation as quickly as possible.
					In order to do so, it’s important that we help you create an amazing listing that will
					improve your listing ranking (i.e. where your property shows up on our page). Here are
					some of the ways you can enhance your listing to get the best possible ranking so that the
					requests start rolling in.
				</ContentText>
				<SectionSubTitle>1. Title</SectionSubTitle>
				<ContentText indent>
					You’re going to want a title that is clear, concise and begins with a capital letter. For
					the sake of simplicity, keep it short and sweet. Your title should be easy to read — and
					don’t forget about spelling! You don’t need to include an address or location as this
					information, as well as any other necessary information, will be available in the
					description.
				</ContentText>
				<SectionSubTitle>2. Photos</SectionSubTitle>
				<ContentText indent>
					First impressions matter, which is why having high-quality photos that showcase your
					property are super important. We recommend no less than 5 pictures, with the first image
					showing the front of your property from the outside.
				</ContentText>
				<SectionSubTitle>3. Features</SectionSubTitle>
				<ContentText indent>
					Whether it’s a pool table, fast WiFi or an outdoor braai area, now’s the time to highlight
					the features that make your property stand out. Be honest and clear when selecting the
					available amenities and keep in mind that more amenities will improve your listing’s
					ranking.
				</ContentText>
				<SectionSubTitle>4. Description</SectionSubTitle>
				<ContentText indent>
					Now’s the time to give all the details about why your property is so amazing — while still
					keeping it short and sweet. From the property’s location, atmosphere, amenities and other
					tenants, it’s up to you to describe what sets your property apart. We recommend using
					bullet points to highlight important amenities such as electricity, Wifi and access to
					public transport.
				</ContentText>
				<SectionSubTitle>5. Reply fast</SectionSubTitle>
				<ContentText indent>
					Don’t dawdle when it comes to replying as your response time will affect your property's
					ranking. Once you get a new enquiry or message, reply as soon as possible because the
					faster you reply, the better your ranking score.
				</ContentText>
				<SectionSubTitle>6. Moderation</SectionSubTitle>
				<ContentText indent>
					To ensure your listing looks great and to make sure you get plenty of requests once it
					goes live, our team will moderate your first listing. This moderation includes checking
					that you have all the important information, a good description and that your listing
					looks all-round amazing. We’ll let you know as soon as your listing is live!
				</ContentText>

				<SectionTitle>Safety</SectionTitle>
				<ContentText>
					As the top student accommodation marketplace in the country and a woman-led company, we
					take safety very seriously — for both our landlords and our tenants. This is to ensure
					everyone’s peace of mind and to keep things running as smoothly as possible. To this end
					we:
				</ContentText>

				<StaticList indent={1}>
					<li>Verify cell phone numbers</li>
					<li>Verify email addresses</li>
					<li>
						Moderate landlords by double-checking that their first listing meets our standards.
					</li>
				</StaticList>

				<ContentText>
					To protect your privacy we encourage you to chat directly through the Digs Chat feature
					and avoid sharing any personal information before verifying the identity of the person you
					are chatting with.
				</ContentText>
				<SectionTitle>NFSAS and private accommodation accreditation</SectionTitle>
				<ContentText>
					The National Student Financial Aid Scheme (NSFAS) is a bursary scheme for students which
					provides funding to South African students for higher education and training. As a
					landlord, you can apply to become NSFAS accredited in order to provide accommodation to
					students who receive funding. In order to do so, your property will need to meet the
					criteria of norms and standards set out by the government and then you can apply for the
					necessary accreditation directly from the tertiary institution. If you would like us to
					add NSFAS accreditation to your listing, send an email to
					<a href="mailto:hello@digsconnect.com"> hello@digsconnect.com</a>.
				</ContentText>
				<SectionTitle>Contact DigsConnect</SectionTitle>
				<ContentText>We always want to hear from you!</ContentText>
				<ContentText>
					You can send us a message on our{' '}
					<LinkText href="https://www.digsconnect.com/contact-us/">Contact Us</LinkText> page.
				</ContentText>
			</PageContainer>
			<Footer />
		</Main>
	);
};

export default LandlordResources;
